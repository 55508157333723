import React from 'react'
import * as styles from '../../assets/styles.module.scss'

import Blackroom from '../../assets/images/customers/blackroom.png'
import Avanti from '../../assets/images/customers/avanti.png'
import Think from '../../assets/images/customers/think.png'
import Timbrat from '../../assets/images/customers/timbrat.png'
import GrupoPremier from '../../assets/images/customers/grupopremier.png'
import Royalfarms from '../../assets/images/customers/royalfarms.png'
import Sinaloa from '../../assets/images/customers/sinaloa.png'
import Antelligence from '../../assets/images/customers/antelligencec.png'
import Amordemi from '../../assets/images/customers/amordemi.png'
import Amplemind from '../../assets/images/customers/amplemind.png'
import Proyecta from '../../assets/images/customers/proyecta.png'
import Globatom from '../../assets/images/customers/globatom.png'

export default () => (
    <section className={styles.Customers}>
        <section className={styles.Centered}>
            <h1>Juvasoft is proud to partner with forward thinking companies, both known and destined to be discovered.</h1>

            <div className={styles.Brands}>
                <img src={Think} />
                <img src={Royalfarms} />
                <img src={Proyecta} />
                <img src={Sinaloa} />
                <img src={Avanti} />
                <img src={Timbrat} />
                <img src={GrupoPremier} />
                <img src={Antelligence} />
                <img src={Amordemi} />
                <img src={Amplemind} />
                <img src={Globatom} />
                <img src={Blackroom} />
            </div>
        </section>
    </section>
)